<div>
  <div class="cx cy">
    <h1 class="heading-text">{{t.translate('Сертификаты')}}</h1>
  </div>
  <div class="color-line"></div>
  <div class="mini-heading pt-2 pb-2">{{t.translate('Сертификат РМРС')}}</div>
  <div class="color-line"></div>
  <div class="cx cy cert">
    <div class="text-min pt-2">
      {{t.translate('Свидетельства о соответствии предприятия. Документы, подтверждающие, что оказываемые предприятием услуги соответствуют требованиям Регистра Судоходства.')}}
    </div>
  </div>
  <div class="cx cy mb-50">
    <img class="cert-image shadow mr-15" [src]="certUrl" (click)="open(certUrl)">
    <img class="cert-image shadow" [src]="certUrl1" (click)="open(certUrl1)">
  </div>
</div>
