<div class="cx cy">
  <h1 class="heading-text">{{t.translate('Услуги')}}</h1>
</div>
<div class="color-line mb-50"></div>
<div class="content">
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content1" (click)="contentClick('content1')">
      <div [class.arrow-down]="content.includes('content1')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Концепт проект')}}</div>
    </div>
    <div collapseContent #content1="collapseContent">
      <div class="mt-4 service-description">
        <div class="">
          {{t.translate('После получения технического задания от заказчика судна проектант начинает разработку концепт проекта. Концепт проект включает в себя разработку предварительных обводов судна, общее расположение, построечную спецификацию, выбор основного оборудования, предварительный заказ материалов и веса судна, а так же другие вспомогательные документы.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content2" (click)="contentClick('content2')">
      <div [class.arrow-down]="content.includes('content2')"  class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Проектная документация (ПДСП)')}}</div>
    </div>
    <div collapseContent  #content2="collapseContent">
      <div class="mt-4 service-description">
        <div class="">{{t.translate('Проектная документация судна в постройке разрабатывается после согласования концепт проекта заказчиком судна. ПДСП включает в себя комплект документов, расчеты, чертежи, обоснования, которые согласно правилам классификации и постройки морских судов направляются для одобрения и согласования в РМРС.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
<!--        <div class="ship">-->
<!--          <img src="assets/services/pdsp.jpg" style="width: 35vw"/>-->
<!--          <img src="assets/services/pdsp-2.jpg" style="width: 12vw"/>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content3" (click)="contentClick('content3')">
      <div [class.arrow-down]="content.includes('content3')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Рабоче-конструкторская документация (РКД)')}}</div>
    </div>
    <div collapseContent #content3="collapseContent">
      <div class="mt-4 service-description">
        <div class="">{{t.translate('После одобрения полного пакета документации ПДСП проектант приступает к разработке РКД по всем видам специализаций (корпус, системы, электрика, достройка).')}}
        </div>
      </div>
      <div class="cx cy pt-20">
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content4" (click)="contentClick('content4')">
      <div [class.arrow-down]="content.includes('content4')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('3D моделирование')}}</div>
    </div>
    <div collapseContent #content4="collapseContent">
      <div class="mt-4 service-description">
        <div class="">
          {{t.translate('Современное проектирование судов невозможно без создания качественной 3D модели. Детально прорисованная 3D модель является основой и базой, на которой отрабатываются все конструктивные решения и выполняются необходимые расчеты судна. В качестве основной программы трехмерного моделирования компания “Nautic Rus” использует систему автоматизированного проектирования “FORAN”. Данная система является одной из лучших на сегодняшний день трехмерной программой проектирования в области судостроения, как по функционалу, так и по объему информации, которую система способна выдавать, при правильном использовании соответствующих модулей программы.')}}
        </div>
        <div class="">
          {{t.translate('“Nautic Rus” обладает всеми необходимыми компетенциями в области проектирования судов. Сотрудники Нашей компании проходят семинары и лекции проводимые разработчиками программы, на которых проводится обучение и ознакомление с возможностями системы “FORAN”.')}}
        </div>
        <div class="">
          {{t.translate('Постоянное совершенство сотрудников и работа разных бюро (электриков, системщиков, корпусников, устройщиков, достройщиков) в единой системе позволяет создать качественную, детально прорисованную модель проектируемого судна, что в свою очередь помогает предотвращать возможные ошибки при проектировании и позволяет выпускать Рабочую Конструкторскую Документацию высокой степени проработки.')}}
        </div>
        <div class="">
          {{t.translate('Так же немаловажным аспектом является корректировка 3D модели и своевременное внесение изменений, которые неизбежно происходят при строительстве судна и в последствии приводит к появлению ошибок и несогласованности систем судна. Наша компания осуществляет техническую поддержку и сопровождение при постройке судна – это дает Заказчику возможность владеть всей необходимой информацией о возможности внесения изменений. Правильное использование и актуализация 3D модели позволяет сократить сроки и издержки при строительстве судна, что является важным конкурентным преимуществом Нашей компании.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
<!--        <div>-->
<!--          <div class="text-center text-bolder pb-5">Вид из трёхмерной модели</div>-->
<!--          <div class="cx cy pl-20">-->
<!--            <img style="width: 24vw" src="assets/services/3d.jpg"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div>-->
<!--          <div class="text-center text-bolder pb-5">Вид монтажа на заказе</div>-->
<!--          <div class="cx cy pr-20">-->
<!--            <img style="width: 24vw" src="assets/services/actual.jpg"/>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content5" (click)="contentClick('content5')">
      <div [class.arrow-down]="content.includes('content5')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Плазово-технологическая документация')}}</div>
    </div>
    <div collapseContent #content5="collapseContent">
      <div class="mt-4 service-description">
        <div class="">
          {{t.translate('Плазово-технологическая документация, разрабатывается на основе разработанной 3d модели судна. И включает в себя раскрой листовых деталей, эскизы профилей, программ резки с нанесением маркировки, изготовление шаблонов гибки профильных и листовых деталей. При необходимости так же предоставляются чертежи оснастки.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content6" (click)="contentClick('content6')">
      <div [class.arrow-down]="content.includes('content6')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Эксплуатационная документация и приемо-сдаточная')}}</div>
    </div>
    <div collapseContent #content6="collapseContent">
      <div class="mt-4 service-description">
        <div class="">
          {{t.translate('Рабочая конструкторская документация предназначена для изучения конструкции изделия и правил его эксплуатации.')}}
        </div>
        <div class="">
          {{t.translate('Приемо-сдаточная документация – документация, в состав которой входит разрешительная документация, дающая право на выполнение строительно-монтажных работ, и исполнительная документация, подтверждающая фактическое выполнение строительно-монтажных работ в объеме, установленном в проектной документации.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content7" (click)="contentClick('content7')">
      <div [class.arrow-down]="content.includes('content7')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Авторский надзор')}}</div>
    </div>
    <div collapseContent #content7="collapseContent">
      <div class="mt-4 service-description">
        <div class="-indent">
          {{t.translate('Авторский надзор - планируемая система контроля проектно-конструкторского предприятия - автора проекта за строительствам и эксплуатацией судов, которая является составной частью работы над проектом.')}}
        </div>
        <div class="">
          {{t.translate('При строительстве и ремонте любого судна часто возникают вопросы по внесению корректив в исходный проект. Изменения могут быть связаны с применением иного оборудования и материалов, оптимизацией конструкций и снижением стоимости строительства. По желанию Заказчика этот процесс может сопровождаться технической поддержкой наших квалифицированных инженеров с соответствующей корректировкой документации и согласованием ее в контролирующих организациях.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 cursor-pointer" [collapse]="content8" (click)="contentClick('content8')">
      <div [class.arrow-down]="content.includes('content8')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Реклассификация судов')}}</div>
    </div>
    <div collapseContent #content8="collapseContent">
      <div class="mt-4 service-description">
        <div class="">
          {{t.translate('Реклассификация судов или смена класса судна – это процедура технической экспертизы и освидетельствования судна с целью замены (выдачи) судовых классификационных свидетельств, выданных ранее старым классификационным обществом, на классификационные свидетельства, выдаваемые новым классификационным обществом.')}}
        </div>
      </div>
      <div class="cx cy pt-20">
      </div>
    </div>
  </div>
  <div>
    <div class="df mt-3 pb-20 cursor-pointer" [collapse]="content9" (click)="contentClick('content9')">
      <div [class.arrow-down]="content.includes('content9')" class="mr-1 cx cy arrow-up">
        <img src="assets/services/right-arrow.svg">
      </div>
      <div class="service-name">{{t.translate('Модернизация и переоборудование судов')}}</div>
    </div>
    <div collapseContent #content9="collapseContent">
      <div class="mt-10 service-description">
        <div class="">
          {{t.translate('Модернизация - совокупность операций по изменению конструкции судна (элемента судна) с целью улучшения технико-эксплуатационных характеристик, условий труда и быта, а также выполнения требований Международных конвенций.')}}
        </div>
      </div>
    </div>
  </div>
</div>
