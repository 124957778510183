<div class="cx cy py-1">
  <div class="heading-text">Данные о результатах специальной оценки условий труда</div>
</div>
<div class="color-line"></div>
<hr class="line">
<div class="cx cy">
  <div class="mini-heading">Перечень рекомендуемых мероприятий по улучшению условий труда</div>
</div>
<hr class="line">
<div class="doc-results">
  <div class="image-doc-container-one" (mouseenter)="setHover(1, 1)" (mouseleave)="resetHover()" (click)="showImage(1, 1)">
    <img src="assets/doc1-page-1.jpg" [class.image-doc-hover]="isHover(1, 1)" class="image-doc-one">
  </div>
  <div class="image-doc-container-two" (mouseenter)="setHover(1, 2)" (mouseleave)="resetHover()" (click)="showImage(1, 2)">
    <img src="assets/doc1-page-2.jpg" [class.image-doc-hover]="isHover(1, 2)" class="image-doc-two">
  </div>
  <div class="image-doc-container-three" (mouseenter)="setHover(1, 3)" (mouseleave)="resetHover()" (click)="showImage(1, 3)">
    <img src="assets/doc1-page-3.jpg" [class.image-doc-hover]="isHover(1, 3)" class="image-doc-three">
  </div>
</div>
<hr class="line">
<div>
  <div class="mini-heading">Сводная ведомость результатов проведения СОУТ</div>
</div>
<hr class="line">
<div class="doc-results">
  <div class="image-doc-container-one" (mouseenter)="setHover(2, 1)" (mouseleave)="resetHover()" (click)="showImage(2, 1)">
    <img src="assets/doc2-page-1.jpg" [class.image-doc-hover]="isHover(2, 1)" class="image-doc-one">
  </div>
  <div class="image-doc-container-two" (mouseenter)="setHover(2, 2)" (mouseleave)="resetHover()" (click)="showImage(2, 2)">
    <img src="assets/doc2-page-2.jpg" [class.image-doc-hover]="isHover(2, 2)" class="image-doc-two">
  </div>
  <div class="image-doc-container-three" (mouseenter)="setHover(2, 3)" (mouseleave)="resetHover()" (click)="showImage(2, 3)">
    <img src="assets/doc2-page-3.jpg" [class.image-doc-hover]="isHover(2, 3)" class="image-doc-three">
  </div>
  <div class="image-doc-container-four" (mouseenter)="setHover(2, 4)" (mouseleave)="resetHover()" (click)="showImage(2, 4)">
    <img src="assets/doc2-page-4.jpg" [class.image-doc-hover]="isHover(2, 4)" class="image-doc-four">
  </div>
  <div class="image-doc-container-five" (mouseenter)="setHover(2, 5)" (mouseleave)="resetHover()" (click)="showImage(2, 5)">
    <img src="assets/doc2-page-5.jpg" [class.image-doc-hover]="isHover(2, 5)" class="image-doc-five">
  </div>
  <div class="image-doc-container-six" (mouseenter)="setHover(2, 6)" (mouseleave)="resetHover()" (click)="showImage(2, 6)">
    <img src="assets/doc2-page-6.jpg" [class.image-doc-hover]="isHover(2, 6)" class="image-doc-six">
  </div>
  <div class="image-doc-container-seven" (mouseenter)="setHover(2, 7)" (mouseleave)="resetHover()" (click)="showImage(2, 7)">
    <img src="assets/doc2-page-7.jpg" [class.image-doc-hover]="isHover(2, 7)" class="image-doc-seven">
  </div>
</div>
