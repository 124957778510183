<div class="pos-r" style="height: 620px; width: 350px;">
  <div class="card-header cx cy pos-r">
    <div>
      {{t.translate('Напишите нам')}}
    </div>
    <div class="pos-close cursor-pointer" (click)="close()">
      <img src="assets/close-button.svg" height="12">
    </div>
  </div>
  <form [formGroup]="sendResume" (ngSubmit)="submitForm()">
    <div class="p-20">
      <div class="pl-5 pr-5 pb-15">
        <div class="mini-text-res mb-3px">{{t.translate('Фамилия*')}}</div>
        <input class="w-100" [class.ng-dirty]="sendResume.get('surname').dirty || sendResume.get('surname').touched" type="text" pInputText formControlName="surname" id="surname">
      </div>
      <div class="pl-5 pr-5 pb-15">
        <div class="mini-text-res mb-3px">{{t.translate('Имя*')}}</div>
        <input class="w-100" [class.ng-dirty]="sendResume.get('name').dirty || sendResume.get('name').touched" type="text" pInputText formControlName="name" id="name">
      </div>
      <div class="pl-5 pr-5 pb-15">
        <div class="mini-text-res mb-3px">{{t.translate('Телефон*')}}</div>
        <p-inputMask [class.ng-dirty]="sendResume.get('phone').dirty || sendResume.get('phone').touched" class="w-100" mask="+7(999) 999-9999" formControlName="phone" id="phone"></p-inputMask>
      </div>
      <div class="pl-5 pr-5 pb-15">
        <div class="mini-text-res mb-3px">{{t.translate('Почта*')}}</div>
        <input [class.ng-dirty]="sendResume.get('email').dirty && sendResume.get('email').invalid" class="w-100" type="email" pInputText formControlName="email" id="email">
      </div>

      <div class="mr-5 ml-5 my-1 upload" (click)="selectFiles.click()" (drop)="$event.preventDefault(); onFilesDrop($event)" (dragover)="$event.stopPropagation(); $event.preventDefault()">
        <div class="cx cy w-100 mb-10">
          <img class="no-actions w-100" src="assets/upload.svg" height="28">
        </div>
        <span class="normal-text w-100 cx cy text-center no-actions">{{t.translate('Перетащите файлы или нажмите для загрузки')}}</span>
      </div>
      <input hidden #selectFiles type="file" (change)="handleFileInput(selectFiles.files)" multiple>
      <div class="h-60px ovf-auto">
        <div *ngFor="let file of loaded" class="df space-between">
          <div class="mini-text-res">{{getFileName(file)}}</div>
          <div (click)="deleteFile(file)">
            <img class="mr-10 cursor-pointer" src="assets/close-button.svg" height="9">
          </div>
        </div>
      </div>

      <div class="button-vac button-send-abs cursor-pointer" [class.disabled-button]="sendResume.invalid || loaded.length == 0" (click)="submitForm()">{{t.translate('Отправить')}}</div>
    </div>
  </form>
</div>
