<div class="cx cy mt-30">
  <h1 class="heading-text mt-30">{{t.translate('Вакансии')}}</h1>
</div>
<div class="color-line"></div>
<div class="content pt-30">
  <div class="vac cursor-pointer" *ngFor="let vacancy of vacancies; let i = index" (mouseenter)="hoveringVacancy = i" (mouseleave)="hoveringVacancy = -1">
    <div class="no-curtain card-vac w-100" [class.no-curtain-hover]="hoveringVacancy == i">
      <div class="vac-name cx cy pb-15">{{vacancy.name}}</div>
<!--      <div class="vac-salary pb-15">{{vacancy.salary}}</div>-->
      <div class="vac-exp">{{vacancy.experience}}</div>
      <div class="vac-location df">
        <div class="mr-15">{{vacancy.city}}</div>
        <div *ngIf="vacancy.metro == 'Автово' || vacancy.metro == 'Avtovo'" class="red-point mt-5px"></div>
        <div *ngIf="vacancy.metro == 'Рыбацкое' || vacancy.metro == 'Rybatskoe'" class="green-point mt-5px"></div>
        <div class="ml-5">{{vacancy.metro}}</div>
      </div>
    </div>
    <div class="curtain" [class.hovered-vacancy]="hoveringVacancy == i">
      <div class="hovered-vacancy-text cx cy">
        <div class="cy cx">
          <div class="button-vac cx cy cursor-pointer mt-10" (click)="openVacancy(i)">{{t.translate('Перейти к вакансии')}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom text-center">Вы можете отправить нам резюме по адресу <b>office@nautic-rus.ru</b>.</div>



