<div class="cx cy py-1 direction-column">
  <div class="heading-text-vac">{{vacancy.getNameUpper()}}</div>
</div>
<div class="content-vac">
  <div class="sal-font mt-30">{{vacancy.salary}}</div>
  <div class="df mt-30">
    <div class="mb-5px">{{t.translate('Требуемый опыт работы:')}}</div>
    <div class="indent-none ml-5">{{vacancy.experience}}</div>
  </div>
  <div class="">{{t.translate('Полная занятость, гибкий график')}}</div>

  <div class="text-bold mb-10 mt-30">{{t.translate('Обязанности:')}}</div>
  <div *ngFor="let resp of vacancy.responsibilities">
    <div class="mb-5px">{{resp}}</div>
  </div>
  <div  class="text-bold mb-10 mt-30">{{t.translate('Требования:')}}</div>
  <div *ngFor="let req of vacancy.requirements">
    <div class="mb-5px">{{req}}</div>
  </div>
  <div class="text-bold mb-10 mt-30">{{t.translate('Условия работы:')}}</div>
  <div *ngFor="let cond of vacancy.conditions">
    <div class="mb-5px">{{cond}}</div>
  </div>
  <div class="df">
    <div class="">{{vacancy.office}}</div>
    <div *ngIf="vacancy.metro == 'м. Автово.' || vacancy.metro == 'Avtovo metro station.'" class="red-point mt-7px ml-10"></div>
    <div *ngIf="vacancy.metro == 'м. Рыбацкое.' || vacancy.metro == 'Rybatskoe metro station.'" class="green-point mt-7px ml-10"></div>
    <div class="ml-5 indent-none">{{vacancy.metro}}</div>
  </div>
  <div *ngIf="vacancy.metro == 'м. Автово.' || vacancy.metro == 'Avtovo metro station.'" class="cx cy mt-10">
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A07b54a317e13373dcac37a05f3d197682a12d754ff0d13be81fdbb7d9a92e1d3&amp;source=constructor" width="100%" height="300" frameborder="0"></iframe>
  </div>
  <div *ngIf="vacancy.metro == 'м. Рыбацкое.' || vacancy.metro == 'Rybatskoe metro station.'" class="cx cy mt-10">
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A20251fe26e0b843c3a63020f40bfc2de03f75f6cdbcbad83eb15b713e49c45c5&amp;source=constructor" width="100%" height="300" frameborder="0"></iframe>
  </div>
  <div class="cx cy pt-30 pb-30">
    <div class="button-v cursor-pointer indent-none" (click)="show()">{{t.translate('Отправить резюме')}}</div>
  </div>
</div>

