<div>
  <div class="cx cy">
    <h1 class="heading-text">{{t.translate('Дизайн')}}</h1>
  </div>
  <div class="color-line"></div>
  <h1 class="mini-heading">{{t.translate('Будущее за синтезом технологий и дизайна')}}</h1>
  <div class="color-line mb-1"></div>
  <div class="content-design">
    <ngx-gallery class="image-gallery" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
    <div class="medium-text pt-2">
      {{t.translate('Мы убеждены – высокотехнологичность и современность должны проявляться как во внутренних системах судна, так и во внешнем облике.')}}
    </div>
    <div class="medium-text">
      {{t.translate('Наша главная задача - спроектировать эргономичное и красивое пространство для людей, длительное время пребывающих в непростых условиях моря. Для людей, выполняющих физически трудную и ответственную работу изо дня в день.')}}
    </div>
    <div class="medium-text">
      {{t.translate('Используя знания о влиянии света и цвета на физическое и эмоциональное состояние человека, мы применяем в дизайне интерьеров траулера решения, которые способствуют качественному отдыху и быстрому восстановлению сил экипажа.')}}
    </div>
    <div class="medium-text">
      {{t.translate('Уникальность подхода к дизайну компании Nautic Rus состоит в том, что мы уделяем внимание абсолютно всем зонам, даже тем, которые на первый взгляд не нуждаются в этом, где превалирует технический подход. Мы интегрируем эстетику в функциональные и технические помещения.')}}
    </div>
    <div class="medium-text">
      {{t.translate('Прививая любовь к упорядоченности и красоте, мы воспитываем уважение к себе и окружающим, делаем шаг к формированию более совершенного общества.')}}
    </div>
    <div class="medium-text">
      {{t.translate('Мы уверены, что созданные нами условия, привлекут большее количество квалифицированных работников на траулер. Наш подход к созданию траулеров нового поколения повлияет на восприятие и позиционирование рыболовной отрасли.')}}
    </div>
  </div>
  <div class="bottom"></div>
</div>
